import { useParams } from "react-router-dom";
import Modal from "../../components/common/Modal";
import { useEffect, useState } from "react";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";
import { useListSchedulesMutation, useAddSchedulesMutation, useEditScheduleMutation, useDeleteScheduleMutation } from "../../store/services/roomsApi";
import { useToast } from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { BiSolidEdit, BiPlus } from "react-icons/bi";

import { MdDelete } from "react-icons/md";
import CustomTimePicker from "../../components/common/CustomTimePicker";

const Schedule = () => {
  const { roomId } = useParams();
  const toast = useToast();
  const [startTime, setStartTime] = useState({ hour: "0", minute: "0" });
  const [endTime, setEndTime] = useState({ hour: "0", minute: "0" });
  const [selectedSchedule, setSelectedSchedule] = useState("");

  const [currentState, setCurrentState] = useState("list");

  const [listSchedule, { data: scheduleData, error, isLoading, isSuccess, isError }] = useListSchedulesMutation();

  const [
    addSchedule,
    { data: addScheduleData, error: addScheduleError, isLoading: addScheduleLoading, isSuccess: addScheduleSuccess, isError: addScheduleIsError },
  ] = useAddSchedulesMutation();

  const [
    editSchedule,
    {
      data: editScheduleData,
      error: editScheduleError,
      isLoading: editScheduleLoading,
      isSuccess: editScheduleSuccess,
      isError: editScheduleIsError,
    },
  ] = useEditScheduleMutation();

  const [
    deleteSchedule,
    {
      data: deleteScheduleData,
      error: deleteScheduleError,
      isLoading: deleteScheduleLoading,
      isSuccess: deleteScheduleSuccess,
      isError: deleteScheduleIsError,
    },
  ] = useDeleteScheduleMutation();

  const handleCreateSchedule = () => {
    let body = {
      daysOfWeek: formData.daysOfWeek,
      // startTime: `${startTime.hour}:${startTime.minute}`,
      // endTime: `${endTime.hour}:${endTime.minute}`,
      startTime: `${startTime.hour}:${startTime.minute}`,
      endTime: `${endTime.hour}:${endTime.minute}`,
      fanMode: formData.fanMode,
      room: roomId,
    };
    console.log(body, "body");
    if (body.daysOfWeek.length == 0) {
      toast({
        title: "Error",
        description: "Please select atleast one day",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (body.startTime == ":" || body.endTime == ":") {
      toast({
        title: "Error",
        description: "Please select start and end time",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    console.log(body, "body");
    addSchedule(body);
  };

  const handleEditSchedule = () => {
    let body = {
      daysOfWeek: formData.daysOfWeek,
      startTime: `${startTime.hour}:${startTime.minute}`,
      endTime: `${endTime.hour}:${endTime.minute}`,
      fanMode: formData.fanMode,
      room: roomId,
    };
    editSchedule({ scheduleId: selectedSchedule, body });
  };
  const handleDeleteSchedule = () => {
    deleteSchedule(selectedSchedule);
  };

  useEffect(() => {
    listSchedule(roomId);
  }, []);

  // useEffect(() => {
  //   // set the values to null
  //   if (currentState == "add") {
  //     setFormData({
  //       daysOfWeek: [],
  //       startTime: "",
  //       endTime: "",
  //       fanMode: "01",
  //       room: roomId,
  //     });
  //     setStartTime({ hour: "", minute: "" });
  //     setEndTime({ hour: "", minute: "" });
  //   }
  // }, [currentState]);

  useEffect(() => {
    if (addScheduleSuccess) {
      listSchedule(roomId);
      setCurrentState("list");
      toast({
        title: "Schedule Added",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [addScheduleSuccess]);
  useEffect(() => {
    if (editScheduleSuccess) {
      listSchedule(roomId);
      setCurrentState("list");
      toast({
        title: "Schedule Edited",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [editScheduleSuccess]);

  useEffect(() => {
    if (addScheduleIsError) {
      toast({
        title: "Error",
        description: addScheduleError.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [addScheduleIsError]);

  useEffect(() => {
    if (editScheduleIsError) {
      toast({
        title: "Error",
        description: editScheduleError.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [editScheduleIsError]);

  useEffect(() => {
    if (deleteScheduleSuccess) {
      listSchedule(roomId);
      toast({
        title: "Schedule Deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setCurrentState("list");
    }
  }, [deleteScheduleSuccess]);

  const [formData, setFormData] = useState({
    daysOfWeek: [],
    startTime: "",
    endTime: "",
    fanMode: "01",
    room: roomId,
  });

  const handleDaySelect = (day) => {
    setFormData((prevFormData) => {
      // Check if the day is already included in the array
      if (prevFormData.daysOfWeek.includes(day)) {
        // Remove the day from the array
        const newDaysOfWeek = prevFormData.daysOfWeek.filter((d) => d !== day);
        return { ...prevFormData, daysOfWeek: newDaysOfWeek };
      } else {
        // Add the day to the array
        const newDaysOfWeek = [...prevFormData.daysOfWeek, day];
        return { ...prevFormData, daysOfWeek: newDaysOfWeek };
      }
    });
  };

  return (
    <Modal className="">
      {currentState == "addDays" && (
        <>
          <div className="back cursor-pointer mb-3  sm:left-3 text-3xl" onClick={() => setCurrentState("add")}>
            <IoIosArrowBack />
          </div>
          <div className="grid grid-cols-1 gap-5 sm:gap-5 sm:w-2/3 m-auto justify-center">
            <div className="h-7 w-full  cursor-pointer">
              <input className="hidden peer" type="radio" name="sun" id="sun" checked={formData.daysOfWeek.includes(0)} />
              <label
                htmlFor="sun"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full block border-gray-400 cursor-pointer"
                onClick={() => handleDaySelect(0)}
              >
                Every Sunday
              </label>
            </div>
            <div className="h-7 w-full ">
              <input className=" hidden peer" type="radio" name="mon" id="mon" checked={formData.daysOfWeek.includes(1)} />
              <label
                htmlFor="mon"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full border-gray-400 block cursor-pointer"
                onClick={() => handleDaySelect(1)}
              >
                Every Monday
              </label>
            </div>
            <div className="h-7 w-full ">
              <input className=" hidden peer" type="radio" name="tue" id="tue" checked={formData.daysOfWeek.includes(2)} />
              <label
                htmlFor="tue"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full block border-gray-400 cursor-pointer"
                onClick={() => handleDaySelect(2)}
              >
                Every Tuesday
              </label>
            </div>
            <div className="h-7 w-full ">
              <input className=" hidden peer" type="radio" name="wed" id="wed" checked={formData.daysOfWeek.includes(3)} />
              <label
                htmlFor="wed"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full block border-gray-400 cursor-pointer"
                onClick={() => handleDaySelect(3)}
              >
                Every Wednesday
              </label>
            </div>
            <div className="h-7 w-full ">
              <input className=" hidden peer" type="radio" name="thu" id="thu" checked={formData.daysOfWeek.includes(4)} />
              <label
                htmlFor="thu"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full block border-gray-400 cursor-pointer"
                onClick={() => handleDaySelect(4)}
              >
                Every Thursday
              </label>
            </div>
            <div className="h-7 w-full ">
              <input className=" hidden peer" type="radio" name="fri" id="fri" checked={formData.daysOfWeek.includes(5)} />
              <label
                htmlFor="fri"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full block border-gray-400 cursor-pointer"
                onClick={() => handleDaySelect(5)}
              >
                Every Friday
              </label>
            </div>
            <div className="h-7 w-full ">
              <input className=" hidden peer" type="radio" name="sat" id="sat" checked={formData.daysOfWeek.includes(6)} />
              <label
                htmlFor="sat"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full block border-gray-400 cursor-pointer"
                onClick={() => handleDaySelect(6)}
              >
                Every Saturday
              </label>
            </div>
          </div>
        </>
      )}
      {currentState == "editDays" && (
        <>
          <div className="back cursor-pointer mb-3  sm:left-3 text-3xl" onClick={() => setCurrentState("edit")}>
            <IoIosArrowBack />
          </div>
          <div className="grid grid-cols-1 gap-5 sm:gap-5 sm:w-2/3 m-auto justify-center">
            <div className="h-7 w-full  cursor-pointer">
              <input className="hidden peer" type="radio" name="sun" id="sun" checked={formData.daysOfWeek.includes(0)} />
              <label
                htmlFor="sun"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full block border-gray-400 cursor-pointer"
                onClick={() => handleDaySelect(0)}
              >
                Every Sunday
              </label>
            </div>
            <div className="h-7 w-full ">
              <input className=" hidden peer" type="radio" name="mon" id="mon" checked={formData.daysOfWeek.includes(1)} />
              <label
                htmlFor="mon"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full border-gray-400 block cursor-pointer"
                onClick={() => handleDaySelect(1)}
              >
                Every Monday
              </label>
            </div>
            <div className="h-7 w-full ">
              <input className=" hidden peer" type="radio" name="tue" id="tue" checked={formData.daysOfWeek.includes(2)} />
              <label
                htmlFor="tue"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full block border-gray-400 cursor-pointer"
                onClick={() => handleDaySelect(2)}
              >
                Every Tuesday
              </label>
            </div>
            <div className="h-7 w-full ">
              <input className=" hidden peer" type="radio" name="wed" id="wed" checked={formData.daysOfWeek.includes(3)} />
              <label
                htmlFor="wed"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full block border-gray-400 cursor-pointer"
                onClick={() => handleDaySelect(3)}
              >
                Every Wednesday
              </label>
            </div>
            <div className="h-7 w-full ">
              <input className=" hidden peer" type="radio" name="thu" id="thu" checked={formData.daysOfWeek.includes(4)} />
              <label
                htmlFor="thu"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full block border-gray-400 cursor-pointer"
                onClick={() => handleDaySelect(4)}
              >
                Every Thursday
              </label>
            </div>
            <div className="h-7 w-full ">
              <input className=" hidden peer" type="radio" name="fri" id="fri" checked={formData.daysOfWeek.includes(5)} />
              <label
                htmlFor="fri"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full block border-gray-400 cursor-pointer"
                onClick={() => handleDaySelect(5)}
              >
                Every Friday
              </label>
            </div>
            <div className="h-7 w-full ">
              <input className=" hidden peer" type="radio" name="sat" id="sat" checked={formData.daysOfWeek.includes(6)} />
              <label
                htmlFor="sat"
                className="border text-left rounded px-3 py-2 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 h-10 w-full block border-gray-400 cursor-pointer"
                onClick={() => handleDaySelect(6)}
              >
                Every Saturday
              </label>
            </div>
          </div>
        </>
      )}
      {currentState == "add" && (
        <div className="form mt-5 relative">
          <div className="back cursor-pointer absolute -top-7 sm:left-3 text-2xl" onClick={() => setCurrentState("list")}>
            <IoIosArrowBack />
          </div>
          <h1 className="text-xl font-semibold mb-3">Add Schedule</h1>
          <span className="text-left sm:w-2/3 m-auto mb-2 block font-semibold  ">Days of Week</span>

          <div
            onClick={() => setCurrentState("addDays")}
            className="sm:w-2/3 flex-col text-left m-auto text-sm  border-gray-400 rounded bg-[#f5f5f5] h-10 flex justify-center pl-2 text-gray-500 items-start"
          >
            <span className="font-semibold capitalize">
              {formData.daysOfWeek
                ? formData.daysOfWeek.length == 7
                  ? "Everyday"
                  : formData.daysOfWeek.length == 0
                  ? "Select Days Of Week"
                  : formData.daysOfWeek
                      .map((day) =>
                        day == 0
                          ? "sun"
                          : day == 1
                          ? "mon"
                          : day == 2
                          ? "tue"
                          : day == 3
                          ? "wed"
                          : day == 4
                          ? "thu"
                          : day == 5
                          ? "fri"
                          : day == 6
                          ? "sat"
                          : ""
                      )
                      .join(", ")
                : "Select Days Of Week"}
            </span>
          </div>

          <div className="mt-5 w-full sm:w-2/3 m-auto">
            <span className="text-left block   mb-1 font-semibold">Start and End Time</span>
            {/* <TimeRangePicker className="w-full sm:w-2/3" disableClock onChange={onChange} value={value} /> */}
            <div className="flex flex-col gap-3 w-full">
              <CustomTimePicker startTime={startTime} onStartTimeChange={setStartTime} placeholder={"Start Time"} />
              <CustomTimePicker startTime={endTime} onStartTimeChange={setEndTime} placeholder={"End Time"} />
            </div>
          </div>

          <span className="text-left sm:w-2/3 m-auto font-semibold mt-5 mb-1 block">Cleaning Mode</span>
          <div className="fanMode sm:w-2/3 grid grid-cols-1 sm:grid-cols-4 m-auto gap-3  min-h-[40px] ">
            <div className="">
              <input className="hidden peer" type="radio" name="fanMode" id="01" checked={formData.fanMode == "01"} />
              <label
                htmlFor="01"
                onClick={() =>
                  setFormData((val) => {
                    return { ...val, fanMode: "01" };
                  })
                }
                className="h-10 sm:h-full flex bg-[#f5f5f5]  justify-center rounded items-center w-full text-sm border border-gray-400 peer-checked:border-black peer-checked:bg-black peer-checked:text-white  peer-checked:border-2 "
              >
                Silent
              </label>
            </div>
            <div className="">
              <input className="hidden peer" type="radio" name="fanMode" id="02" checked={formData.fanMode == "02"} />
              <label
                htmlFor="02"
                onClick={() =>
                  setFormData((val) => {
                    return { ...val, fanMode: "02" };
                  })
                }
                className="h-10 sm:h-full flex bg-[#f5f5f5] justify-center rounded items-center w-full text-sm border border-gray-400 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 "
              >
                Standard
              </label>
            </div>
            <div className="">
              <input className="hidden peer" type="radio" name="fanMode" id="03" checked={formData.fanMode == "03"} />
              <label
                htmlFor="03"
                onClick={() =>
                  setFormData((val) => {
                    return { ...val, fanMode: "03" };
                  })
                }
                className="h-10 sm:h-full flex bg-[#f5f5f5] justify-center rounded items-center w-full text-sm border border-gray-400 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 "
              >
                Turbo
              </label>
            </div>
            <div className="">
              <input className="hidden peer" type="radio" name="fanMode" id="04" checked={formData.fanMode == "04"} />
              <label
                htmlFor="04"
                onClick={() =>
                  setFormData((val) => {
                    return { ...val, fanMode: "04" };
                  })
                }
                className="h-10 sm:h-full flex bg-[#f5f5f5] justify-center rounded items-center w-full text-sm border border-gray-400 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 "
              >
                Deep Clean
              </label>
            </div>
          </div>
          <button onClick={handleCreateSchedule} className="mt-5 bg-black  py-2 text-white rounded px-3">
            Add Schedule
          </button>
        </div>
      )}
      {currentState == "edit" && (
        <div className="form mt-5 relative">
          <div className="back cursor-pointer absolute -top-7 sm:left-3 text-2xl" onClick={() => setCurrentState("list")}>
            <IoIosArrowBack />
          </div>
          <h1 className="text-xl font-semibold mb-3">Edit Schedule</h1>
          <span className="text-left sm:w-2/3 m-auto mb-2 block font-semibold  ">Days of Week</span>

          <div
            onClick={() => setCurrentState("addDays")}
            className="sm:w-2/3 flex-col text-left m-auto text-sm  border-gray-400 rounded bg-[#f5f5f5] h-10 flex justify-center pl-2 text-gray-500 items-start"
          >
            <span className="font-semibold capitalize">
              {formData.daysOfWeek
                ? formData.daysOfWeek.length == 7
                  ? "Everyday"
                  : formData.daysOfWeek.length == 0
                  ? "Select Days Of Week"
                  : formData.daysOfWeek
                      .map((day) =>
                        day == 0
                          ? "sun"
                          : day == 1
                          ? "mon"
                          : day == 2
                          ? "tue"
                          : day == 3
                          ? "wed"
                          : day == 4
                          ? "thu"
                          : day == 5
                          ? "fri"
                          : day == 6
                          ? "sat"
                          : ""
                      )
                      .join(", ")
                : "Select Days Of Week"}
            </span>
          </div>

          <div className="mt-5 w-full sm:w-2/3 m-auto">
            <span className="text-left block font-semibold mb-1">Start and End Time</span>
            {/* <TimeRangePicker className="w-full sm:w-2/3" disableClock onChange={onChange} value={value} /> */}
            <div className="flex flex-col gap-3 w-full">
              <CustomTimePicker startTime={startTime} onStartTimeChange={setStartTime} placeholder={"Start Time"} />
              <CustomTimePicker startTime={endTime} onStartTimeChange={setEndTime} placeholder={"End Time"} />
            </div>
          </div>

          <span className="text-left sm:w-2/3 m-auto font-semibold mt-5 mb-1 block">Cleaning Mode</span>
          <div className="fanMode sm:w-2/3 grid grid-cols-1 sm:grid-cols-4 m-auto gap-3  min-h-[40px] ">
            <div className="">
              <input className="hidden peer" type="radio" name="fanMode" id="01" checked={formData.fanMode == "01"} />
              <label
                htmlFor="01"
                onClick={() =>
                  setFormData((val) => {
                    return { ...val, fanMode: "01" };
                  })
                }
                className="h-10 sm:h-full bg-[#f5f5f5] flex justify-center rounded items-center w-full text-sm border border-gray-400 peer-checked:border-black peer-checked:bg-black peer-checked:text-white  peer-checked:border-2 "
              >
                Silent
              </label>
            </div>
            <div className="">
              <input className="hidden peer" type="radio" name="fanMode" id="02" checked={formData.fanMode == "02"} />
              <label
                htmlFor="02"
                onClick={() =>
                  setFormData((val) => {
                    return { ...val, fanMode: "02" };
                  })
                }
                className="h-10 sm:h-full bg-[#f5f5f5] flex justify-center rounded items-center w-full text-sm border border-gray-400 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 "
              >
                Standard
              </label>
            </div>
            <div className="">
              <input className="hidden peer" type="radio" name="fanMode" id="03" checked={formData.fanMode == "03"} />
              <label
                htmlFor="03"
                onClick={() =>
                  setFormData((val) => {
                    return { ...val, fanMode: "03" };
                  })
                }
                className="h-10 sm:h-full bg-[#f5f5f5] flex justify-center rounded items-center w-full text-sm border border-gray-400 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 "
              >
                Turbo
              </label>
            </div>
            <div className="">
              <input className="hidden peer" type="radio" name="fanMode" id="04" checked={formData.fanMode == "04"} />
              <label
                htmlFor="04"
                onClick={() =>
                  setFormData((val) => {
                    return { ...val, fanMode: "04" };
                  })
                }
                className="h-10 sm:h-full bg-[#f5f5f5] flex justify-center rounded items-center w-full text-sm border border-gray-400 peer-checked:border-black peer-checked:bg-black peer-checked:text-white peer-checked:border-2 "
              >
                Deep Clean
              </label>
            </div>
          </div>
          <button onClick={handleEditSchedule} className="mt-5 bg-black  py-2 text-white rounded px-3">
            Save
          </button>
          <button onClick={handleDeleteSchedule} className="mt-5 ml-3 bg-black  py-2 text-white rounded px-3">
            Delete
          </button>
        </div>
      )}

      {currentState == "list" && (
        <div className="mt-4">
          <div className="relative my-2">
            <h1 className="text-lg font-bold">Schedules</h1>
            {/* <button
              onClick={() => {
                setFormData({ daysOfWeek: [], startTime: "", endTime: "" });
                setStartTime({ hour: "00", minute: "00" });
                setEndTime({ hour: "00", minute: "00" });
                setCurrentState("add");
              }}
              className="absolute font-bold text-xl left-0 -top-2 bg-black px-3 py-2  rounded text-white"
            >
              <BiPlus />
            </button> */}
          </div>
          <div className="grid grid-cols-1 gap-3 ">
            {scheduleData?.length == 0 && (
              <div className="">
                <h1 className="text-center mt-5">No Schedules Found</h1>
                <button onClick={() => setCurrentState("add")} className="mt-5 bg-black text-white px-3 py-2 rounded-md">
                  Add Schedule
                </button>
              </div>
            )}
            {scheduleData?.map((schedule) => (
              <div key={schedule.id} className="bg-gray-100  mt-2 relative shadow-container flex flex-col  items-start  p-3 rounded">
                <div className="flex text-2xl font-semibold gap-1">
                  <h1 className=""> {schedule.startTime} </h1> <h1> - {schedule.endTime}</h1>
                </div>
                <h1 className="text-lg mt-1 ">
                  {schedule.fanMode == "01" ? "Silent" : schedule.fanMode == "02" ? "Standard" : schedule.fanMode == "03" ? "Turbo" : "Deep Clean"}{" "}
                </h1>
                <h1 className="w-[70%] text-left mt-2">
                  <div className="flex justify-between">
                    <div
                      className={`sun ${
                        schedule.daysOfWeek.includes(0) ? "bg-black text-white" : "bg-gray-200 text-black"
                      } text-xs rounded-full w-[22px] h-[22px]  flex justify-center items-center border border-gray-400`}
                    >
                      S
                    </div>
                    <div
                      className={`sun ${
                        schedule.daysOfWeek.includes(1) ? "bg-black text-white" : "bg-gray-200 text-black"
                      } text-sm rounded-full w-[22px] h-[22px] flex justify-center items-center border border-gray-400`}
                    >
                      M
                    </div>
                    <div
                      className={`sun ${
                        schedule.daysOfWeek.includes(2) ? "bg-black text-white" : "bg-gray-200 text-black"
                      } text-sm rounded-full w-[22px] h-[22px] flex justify-center items-center border border-gray-400`}
                    >
                      T
                    </div>
                    <div
                      className={`sun ${
                        schedule.daysOfWeek.includes(3) ? "bg-black text-white" : "bg-gray-200 text-black"
                      } text-sm rounded-full w-[22px] h-[22px] flex justify-center items-center border border-gray-400`}
                    >
                      W
                    </div>
                    <div
                      className={`sun ${
                        schedule.daysOfWeek.includes(4) ? "bg-black text-white" : "bg-gray-200 text-black"
                      } text-sm rounded-full w-[22px] h-[22px] flex justify-center items-center border border-gray-400`}
                    >
                      T
                    </div>
                    <div
                      className={`sun ${
                        schedule.daysOfWeek.includes(5) ? "bg-black text-white" : "bg-gray-200 text-black"
                      } text-sm rounded-full w-[22px] h-[22px] flex justify-center items-center border border-gray-400`}
                    >
                      F
                    </div>
                    <div
                      className={`sun ${
                        schedule.daysOfWeek.includes(6) ? "bg-black text-white" : "bg-gray-200 text-black"
                      } text-sm rounded-full w-[22px] h-[22px] flex justify-center items-center border border-gray-400`}
                    >
                      S
                    </div>
                  </div>
                </h1>
                <div className="absolute flex flex-col text-xl justify-between top-0 py-4 h-full right-5">
                  <div
                    onClick={() => {
                      setFormData(schedule);
                      setStartTime({ hour: schedule.startTime.split(":")[0], minute: schedule.startTime.split(":")[1] });
                      setEndTime({ hour: schedule.endTime.split(":")[0], minute: schedule.endTime.split(":")[1] });
                      setSelectedSchedule(schedule._id);
                      setCurrentState("edit");
                    }}
                    className="edit  cursor-pointer"
                  >
                    <BiSolidEdit />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default Schedule;
