import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { persistor, store } from "./store/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { ChakraProvider } from "@chakra-ui/react";
import { OpenCvProvider } from "opencv-react";

// import { persistor, store } from './redux/store';
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <OpenCvProvider>
          <ChakraProvider>
            <Router>
              <App />
            </Router>
          </ChakraProvider>
        </OpenCvProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>
);
