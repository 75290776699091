import React, { useEffect, useRef, useState } from "react";
import { useOpenCv } from "opencv-react";
import { useVideoSubmitMutation } from "../store/services/roomsApi";

import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function MotionDetectionComponent() {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [motionDetected, setMotionDetected] = useState(false);
  const [showSlowDownMessage, setShowSlowDownMessage] = useState(false); // State for controlling the visibility of the "Slow down" message
  const { cv } = useOpenCv();
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const isComponentMounted = useRef(true);
  const [compatibleCam, setCompatibleCam] = useState(true);

  const [videoComplete, setVideoComplete] = useState(false);
  const [videoURL, setVideoUrl] = useState(false);
  const [videoBlob, setVideoBlob] = useState();

  const [cameraInited, setCameraInited] = useState(false);

  const [nonZeroCount, setNonZeroCount] = useState(0);

  // accelometer

  const [rawAcceleration, setRawAcceleration] = useState({ x: 0, y: 0, z: 0 });
  const [smoothedAcceleration, setSmoothedAcceleration] = useState({ x: 0, y: 0, z: 0 });
  const [permissionGranted, setPermissionGranted] = useState(false);

  const [submitVideo, { data: videoData, error: videoError, isError: isVideoError, isSuccess: isVideoSuccess, isLoading: isVideoLoading }] =
    useVideoSubmitMutation();

  useEffect(() => {
    if (isVideoSuccess) {
      toast({
        title: "Video Submitted",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate(-1);
    }
  }, [isVideoSuccess]);

  useEffect(() => {
    if (isVideoError) {
      toast({
        title: "Something went wrong! Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [isVideoError]);

  useEffect(() => {
    // Check if DeviceOrientationEvent is available and if permission is required
    if (typeof DeviceMotionEvent !== "undefined" && typeof DeviceMotionEvent.requestPermission === "function") {
      // Request permission
      DeviceMotionEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === "granted") {
            setPermissionGranted(true);
          } else {
            setPermissionGranted(false);
            alert("You need to grant motion sensor permission in settings.");
          }
        })
        .catch(console.error);
    } else {
      // Handle browsers that don't require permission
      setPermissionGranted(true);
    }
  }, []);

  useEffect(() => {
    if (!permissionGranted) return;
    const handleMotionEvent = (event) => {
      const { accelerationIncludingGravity } = event;
      setRawAcceleration({
        x: accelerationIncludingGravity.x,
        y: accelerationIncludingGravity.y,
        z: accelerationIncludingGravity.z,
      });
    };

    window.addEventListener("devicemotion", handleMotionEvent);

    return () => {
      window.removeEventListener("devicemotion", handleMotionEvent);
    };
  }, [permissionGranted]);

  useEffect(() => {
    // Simple moving average filter
    const alpha = 0.1; // Smoothing factor. Adjust based on responsiveness vs. smoothness needs
    const smoothedX = alpha * rawAcceleration.x + (1 - alpha) * smoothedAcceleration.x;
    const smoothedY = alpha * rawAcceleration.y + (1 - alpha) * smoothedAcceleration.y;
    const smoothedZ = alpha * rawAcceleration.z + (1 - alpha) * smoothedAcceleration.z;

    setSmoothedAcceleration({
      x: smoothedX,
      y: smoothedY,
      z: smoothedZ,
    });
  }, [rawAcceleration]);

  // accelometer end

  useEffect(() => {
    isComponentMounted.current = true;

    if (!cv || !videoRef.current || !canvasRef.current) return;
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let prevFrame = null;

    const handleLoadedMetadata = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    };

    const checkMotion = () => {
      if (
        !videoRef.current ||
        !canvasRef.current ||
        !videoRef.current.srcObject ||
        videoRef.current.videoWidth === 0 ||
        videoRef.current.videoHeight === 0
      ) {
        // Video stream not yet available or metadata not loaded, wait for it

        requestAnimationFrame(checkMotion);
        return;
      }

      const video = videoRef.current;
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const currentFrame = ctx.getImageData(0, 0, canvas.width, canvas.height);

      setCameraInited(true);
      // Perform motion detection using OpenCV.js
      if (prevFrame) {
        const currMotionDetected = detectMotion(prevFrame, currentFrame);
        setMotionDetected(currMotionDetected);

        // Show the "Slow down" message longer if motion is detected
        // if (currMotionDetected) {
        //   setShowSlowDownMessage(true);
        //   setTimeout(() => {
        //     setShowSlowDownMessage(false);
        //   }, 3000); // Adjust the duration (in milliseconds) as needed
        // }
      }

      prevFrame = currentFrame;

      // Request next frame if component is still mounted
      if (isComponentMounted.current) {
        requestAnimationFrame(checkMotion);
      }
    };

    // Access webcam stream
    navigator?.mediaDevices
      ?.getUserMedia({ video: { facingMode: "environment", width: 1280, height: 720, frameRate: { ideal: 30 } } }) // Set constraints for video resolution and frame rate
      .then((stream) => {
        streamRef.current = stream;
        video.srcObject = stream;
        video.addEventListener("loadedmetadata", () => {
          video.play(); // Start video playback
        });
      })
      .catch((error) => {
        setCompatibleCam(false);
        console.error("Error accessing camera:", error);
      });

    // Listen for video metadata loaded event
    video.addEventListener("loadedmetadata", handleLoadedMetadata);

    // Start motion detection loop
    checkMotion();

    // Cleanup function
    return () => {
      if (streamRef.current) {
        const tracks = streamRef.current.getTracks();
        tracks.forEach((track) => track.stop());
      }
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      isComponentMounted.current = false;
    };
  }, [cv]);

  useEffect(() => {
    if (+Math.sqrt(smoothedAcceleration.x * smoothedAcceleration.x) > 0.6) {
      setShowSlowDownMessage(true);
      setTimeout(() => {
        setShowSlowDownMessage(false);
      }, 3000); // Adjust the duration (in milliseconds) as needed
    }
  }, [smoothedAcceleration]);

  const stopMediaStream = () => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  // Function to perform motion detection using OpenCV.js
  const detectMotion = (prevFrame, currentFrame) => {
    try {
      // Convert image data to OpenCV Mats
      const prevMat = cv.matFromArray(prevFrame.height, prevFrame.width, cv.CV_8UC4, prevFrame.data);
      const currentMat = cv.matFromArray(currentFrame.height, currentFrame.width, cv.CV_8UC4, currentFrame.data);

      // Convert frames to grayscale
      const prevGray = new cv.Mat();
      const currentGray = new cv.Mat();
      cv.cvtColor(prevMat, prevGray, cv.COLOR_RGBA2GRAY);
      cv.cvtColor(currentMat, currentGray, cv.COLOR_RGBA2GRAY);

      // Compute absolute difference between frames
      const diff = new cv.Mat();
      cv.absdiff(prevGray, currentGray, diff);

      // Threshold difference image to detect motion
      const threshold = 200; // Adjust this threshold as needed
      const thresholded = new cv.Mat();
      cv.threshold(diff, thresholded, threshold, 255, cv.THRESH_BINARY);

      // Count non-zero pixels in thresholded image
      const nonZeroCount = cv.countNonZero(thresholded);

      // Release Mats
      prevMat.delete();
      currentMat.delete();
      prevGray.delete();
      currentGray.delete();
      diff.delete();
      thresholded.delete();

      // If more than a certain number of c pixels differ, consider it as motion
      const motionThreshold = 400; // Adjust this threshold as needed

      setNonZeroCount(nonZeroCount);
      return nonZeroCount > motionThreshold;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const startRecording = (state) => {
    if (state === 1) {
      setIsRecording(true);
      setRecordedChunks([]);
      const stream = videoRef.current.srcObject;
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = handleDataAvailable;
      mediaRecorderRef.current.start();
    } else if (state === 0) {
      setIsRecording(false);
      mediaRecorderRef.current.stop();
    }
  };

  // Function to handle recorded data
  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      setRecordedChunks([...recordedChunks, event.data]);
    }

    const blob = new Blob([event.data], { type: "video/mp4" });
    setVideoBlob(blob);
    console.log(blob);
    const url = URL.createObjectURL(blob);
    // You can save the recorded video or use it as needed
    console.log("Recorded video:", url);
    setVideoComplete(true);
    console.log(url);
    setVideoUrl(url);
    // window.open(url);
    stopMediaStream();

    // save the blob in mp4 format in memory
  };

  const onSubmit = async () => {
    // Submit the recorded video
    console.log("Video submitted:", videoBlob);
    const formData = new FormData();
    formData.append("room", roomId);

    const videoFile = new File([videoBlob], `${roomId}.mp4`, { type: "video/mp4", contentType: "video/mp4" });

    console.log(videoBlob.size, videoFile.size);
    formData.append("video", videoFile);

    submitVideo(formData);
  };

  return (
    <div>
      {isVideoLoading && <h1>Loading...</h1>}
      {compatibleCam ? (
        <div className="w-11/12 m-auto">
          {!videoComplete ? (
            <div className="vid relative   rounded-lg overflow-hidden  ">
              {/* <h1 className="my-3 text-xl font-semibold">Please Record a video of your room slowly</h1> */}
              <video ref={videoRef} autoPlay muted playsInline style={{ display: "none" }}></video>
              <canvas ref={canvasRef} style={{ width: "100%", height: "auto" }}></canvas>
              <div className="warn absolute top-1/2 text-red-500 font-semibold text-2xl  -translate-y-1/2 left-1/2 -translate-x-1/2">
                {showSlowDownMessage && <p>Please Slow down! </p>}
              </div>

              {cameraInited && (
                <div className="controls absolute left-1/2 -translate-x-1/2 bottom-4">
                  {isRecording ? (
                    <button className="text-2xl p-2 rounded-full border-2  bg-white" onClick={() => startRecording(0)}>
                      <div className="w-7 h-7 m-1 rounded-md bg-red-500 text-red-500">.</div>
                    </button>
                  ) : (
                    <button className="text-2xl p-2 rounded-full border-2  bg-white" onClick={() => startRecording(1)}>
                      <div className="w-7 h-7 rounded-full m-1 bg-red-500 text-red-500">.</div>
                    </button>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className=" w-11/12 m-auto ">
              {/* <h1 className="my-3 text-xl font-semibold">Is the video Clear?</h1> */}
              <div className="vid relative  rounded-lg overflow-hidden  ">
                <video src={videoURL} autoPlay muted playsInline controls></video>
              </div>
              <div className="actions flex gap-5 ">
                <button onClick={() => window.location.reload(false)} className="bg-[#323232] text-white w-3/5 h-8 mt-6 rounded-md">
                  Retake
                </button>
                <button onClick={onSubmit} className="bg-[#323232] text-white w-3/5 h-8 mt-6 rounded-md">
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="">
          <h1>No Compatible Camera Detected</h1>
        </div>
      )}
    </div>
  );
}

export default MotionDetectionComponent;
