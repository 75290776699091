import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const roomApi = createApi({
  reducerPath: "roomApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("targetUserID", getState().auth.targetUserID);
      }
    },
  }),
  endpoints: (builder) => ({
    listRooms: builder.mutation({
      query: () => {
        return {
          url: "/room",
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),

    findRoomByID: builder.query({
      query: (roomId) => {
        return {
          url: `/room/${roomId}`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    prevRoomAvg: builder.mutation({
      query: (roomObject) => {
        return {
          url: `/room/avg/${roomObject.roomID}`,
          method: "get",
          params: { date: roomObject.date },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    roomGraphData: builder.mutation({
      query: (roomObject) => {
        return {
          url: `/room/graphData/${roomObject.roomID}`,
          method: "get",
          params: { date: roomObject.date },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    roomControl: builder.mutation({
      query: (roomObject) => {
        return {
          url: `/room/ctrl/${roomObject.room}`,
          method: "put",
          body: roomObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    createRoom: builder.mutation({
      query: (body) => {
        return {
          url: `/room`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    editRoom: builder.mutation({
      query: (bodyObject) => {
        return {
          url: `/room/${bodyObject.room}`,
          method: "put",
          body: bodyObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    removeRoom: builder.mutation({
      query: (roomId) => {
        return {
          url: `/room/${roomId}`,
          method: "delete",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    listSchedules: builder.mutation({
      query: (roomId) => {
        return {
          url: `/schedule/${roomId}`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    addSchedules: builder.mutation({
      query: (body) => {
        return {
          url: `/schedule`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    editSchedule: builder.mutation({
      query: (reqBody) => {
        return {
          url: `/schedule/${reqBody.scheduleId}`,
          method: "put",
          body: reqBody.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    deleteSchedule: builder.mutation({
      query: (scheduleId) => {
        return {
          url: `/schedule/${scheduleId}`,
          method: "delete",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    toggleSchedule: builder.mutation({
      query: (bodyObject) => {
        return {
          url: `/schedule/toggle/${bodyObject.roomId}`,
          method: "put",
          body: bodyObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    videoSubmit: builder.mutation({
      query: (body) => {
        return {
          url: `/room/submitVideo`,
          method: "post",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
  }),
});

export const {
  useToggleScheduleMutation,
  useListRoomsMutation,
  useFindRoomByIDQuery,
  useCreateRoomMutation,
  useEditRoomMutation,
  useRoomGraphDataMutation,
  usePrevRoomAvgMutation,
  useRoomControlMutation,
  useRemoveRoomMutation,
  useListSchedulesMutation,
  useAddSchedulesMutation,
  useEditScheduleMutation,
  useDeleteScheduleMutation,
  useVideoSubmitMutation,
} = roomApi;
